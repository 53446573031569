<template>
  <div class="admin-managed-groups">
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin')"
          icon="left"
        ></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        Admin - Managed Groups
      </h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search
          size="large"
          placeholder="Search managed groups"
          v-model="search_query"
        />
        <!-- / Search -->

        <a-button
          @click.prevent="loadManagedGroups"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
          @click.prevent="createManagedGroup"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        >
      </div>
    </div>
    <!-- List wrapper -->
    <div class="managed-groups-list-wrapper">
      <a-alert
        style="margin-bottom: 25px"
        message="Creating managed groups is essential for announcements, as it allows you to form distinct membership groups to receive targeted notifications."
      >
      </a-alert>

      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No managed groups -->
      <a-alert
        v-if="!isLoading && managedGroupsToShow.length == 0"
        type="info"
        message="No managed groups to show"
      >
      </a-alert>
      <!-- / No managed groups -->

      <!-- Loaded -->
      <div
        class="managed-groups-list"
        v-if="!isLoading && managedGroupsToShow.length"
      >
        <a-row type="flex" :gutter="20">
          <a-col
            :span="colSpan"
            v-for="managedGroup in managedGroupsToShow"
            :key="managedGroup.id"
          >
            <managed-group-list-item
              :managed-group="managedGroup"
              :tenant-id="tenantId"
              :org-id="selectedOrganisation.id"
              @selected="() => navigateToManagedGroup(managedGroup)"
            ></managed-group-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ManagedGroupListItem from "./ManagedGroups/ManagedGroupListItem.vue";
export default {
  components: { ManagedGroupListItem },

  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadManagedGroups();
  },

  watch: {
    selectedOrganisation() {
      this.loadManagedGroups();
    },
  },

  methods: {
    ...mapActions("adminManagedGroups", {
      loadManagedGroups: "loadManagedGroups",
      setSearchQuery: "setSearchQuery",
    }),

    navigateToManagedGroup(managedGroup) {
      this.$router.push("/admin/managed-groups/" + managedGroup.id + "/edit");
    },

    createManagedGroup() {
      this.$router.push("/admin/managed-groups/create");
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    ...mapGetters("adminManagedGroups", {
      isLoading: "isLoading",
      managedGroups: "managedGroups",
      managedGroupsToShow: "managedGroupsToShow",
      searchQuery: "searchQuery",
    }),

    search_query: {
      set(val) {
        this.setSearchQuery(val);
      },
      get() {
        return this.searchQuery;
      },
    },

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },
  },
};
</script>

<style scoped lang="scss">
.managed-group-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}
</style>